import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { graphql } from 'gatsby'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import ProgramTile from '~components/ProgramTile'
import { StaticImage } from 'gatsby-plugin-image'
import MailchimpForm from '~components/MailchimpForm'
import RichText from '~components/RichText'
import Image from '~components/Image'

const ProgramIndex = ({ data }) => {
	const { page, programs } = data

	const oneOnOneSessions = programs?.nodes?.filter(program => program.slug.current === 'one-on-one-sessions')

	const remainingPrograms = programs?.nodes?.filter(program => program.slug.current !== 'one-on-one-sessions')

	return (
		<Wrap>
			<Header><Title className='h3'>{page.title}</Title></Header>
			<Programs>
				<StyledProgramTile program={oneOnOneSessions[0]} index={0} hoverTilt/>
				{remainingPrograms?.map((program, i) => (
					<StyledProgramTile key={program.title} program={program} index={i + 1} hoverTilt/>
				))}
			</Programs>
			<SignUp>
				<SignUpTitle>{page?.signUpTitle}</SignUpTitle>
				<SignUpText><RichText content={page.signUpText}/></SignUpText>
				<SignUpForm successMessage={'Success!'} center/>
				<SignUpImage image={page.signUpImage}/>
			</SignUp>
			<DataViewer data={{...programs, ...page}} name="page"/>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const Header = styled(Section)`
	margin: var(--l) 0;
`
const Title = styled.h1`
	grid-column: span 12;
	text-align: center;
	max-width: 100%;
`
const Programs = styled(Section)`
	margin: var(--xl) 0;
	> div {
		grid-row-gap: var(--l);
	}
`
const StyledProgramTile = styled(ProgramTile)`
	grid-column: span 4;
	${tablet}{
		grid-column: span 6;
	}
	${mobile}{
		grid-column: span 12;
	}
`
const SignUp = styled(Section)`
	grid-column: span 12;
	margin-bottom: var(--xl);
`
const SignUpTitle = styled.h3`
	grid-column: span 12;
	max-width: 500px;
	margin: 0 auto var(--m);
`
const SignUpText = styled.div`
	grid-column: span 12;
	max-width: 430px;
	margin: 0 auto var(--m);
	text-align: center;
`
const SignUpImage = styled(Image)`
	grid-column: span 12;
	max-width: 440px;
	margin: 0 auto;
	width: 100%;
`
const SignUpForm = styled(MailchimpForm)`
	grid-column: span 12;
	max-width: 427px;
	margin: 0 auto var(--l);
	width: 100%;
	text-align: center;
`

export const query = graphql`
  query ProgramsQuery($id: String)  {
    page: sanityProgramIndex(id: {eq: $id}) {
			title
			seo{
				...seo
			}
			signUpText: _rawSignUpText(resolveReferences: {maxDepth: 5})
			signUpImage {
				...imageWithAlt
			}
			signUpTitle
			title
		}
		programs: allSanityProgram {
			nodes {
				headerImage {
					...imageWithAlt
				}
				slug {
					current
				}
				time
				title
			}
		}
  }
`

ProgramIndex.propTypes = {
	data: PropTypes.object
}

export default ProgramIndex